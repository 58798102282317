import { InjectedConnector } from '@web3-react/injected-connector';

export const injected = new InjectedConnector({
  supportedChainIds: [5]
});

export const personalSign = async (msg, address) => {
  try {
    console.log(`msg=${msg} address=${address}`);
    console.log(window);
    const ethResult = await (window as any).ethereum.request({ // eslint-disable-line @typescript-eslint/dot-notation
      method: 'personal_sign',
      params: [msg, address],
    });
    console.log('theResult=', ethResult);
    return ethResult;
  } catch (err) {
    console.log(err);
    return '';
  }
};
