import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';

export enum LoadingStatus {
  NONE = 1,
  LOADING = 2,
  COMPLETED = 3
}

interface UserState {
  logged: boolean;
  connected: boolean;
  address: string;
  url: string;
  searchMode: boolean; // only for bSearch
  isWindowScrollBottom: boolean;
  isTryingLogin: boolean;
  isSigning: boolean;
}

const initialState: UserState = {
  logged: false,
  connected: false,
  address: '',
  url: null,
  searchMode: false,
  isWindowScrollBottom: false,
  isTryingLogin: false,
  isSigning: false
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearState(state: UserState) {
      state.logged = false;
      state.connected = false;
      state.address = null;
      state.url = '';
      state.searchMode = false;
      state.isWindowScrollBottom = false;
      state.isTryingLogin = false;
      state.isSigning = false;
    },
    setLogged(state: UserState, action: PayloadAction<boolean>): void {
      const flag = action.payload;
      state.logged = flag;
    },
    setConnected(state: UserState, action: PayloadAction<boolean>): void {
      const flag = action.payload;
      state.connected = flag;
    },
    setWindowScrollBottom(state: UserState, action: PayloadAction<boolean>): void {
      state.isWindowScrollBottom = action.payload as boolean;
    },
    setAddress(state: UserState, action: PayloadAction<string>): void {
      state.address = action.payload as string;
    },
    setUrl(state: UserState, action: PayloadAction<string>): void {
      state.url = action.payload as string;
    },
    setSearchMode(state: UserState, action: PayloadAction<boolean>): void {
      state.searchMode = action.payload as boolean;
    },
    setTryingLogin(state: UserState, action: PayloadAction<boolean>): void {
      state.isTryingLogin = action.payload as boolean;
    },
    setSigning(state: UserState, action: PayloadAction<boolean>): void {
      state.isSigning = action.payload as boolean;
    },
  }
});

export const clearUserState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const setLogged = (flag: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setLogged(flag));
};

export const setConnected = (flag: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setConnected(flag));
};

export const setWindowScrollBottom = (flag: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setWindowScrollBottom(flag));
};

export const setAddress = (address: string) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setAddress(address));
};

export const setUrl = (url: string) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setUrl(url));
};

export const setSearchMode = (flag: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setSearchMode(flag));
};

export const setTryingLogin = (flag: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setTryingLogin(flag));
};

export const setSigning = (flag: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setSigning(flag));
};

export const { reducer } = slice;

export default slice;
