/* eslint-disable */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  Paper,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { Stack } from '@mui/system';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import { GlobalParams } from 'src/config';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from "src/store";
import { updateGlobalState } from "src/slices/global";
import { Box } from "@material-ui/core";

const DATA_TYPE_SINGLE_VALUE = 0;
const DATA_TYPE_LINK_VALUE = 1;
const DATA_TYPE_ARRAY_SINGLE_VALUE = 2;
const DATA_TYPE_ARRAY_COMPLEX_VALUE = 3;
const DATA_TYPE_ARRAY_SINGLE_KIND_VALUE = 4;
const DATA_TYPE_DICT_SINGLE_VALUE = 5;
const DATA_TYPE_DICT_COMPLEX_VALUE = 6;

export const makeMessage = (url: string, method: string) => `${url}${method}`;

function is_simple_data(data) {
  if (data === undefined || data === null) return true;
  if (Array.isArray(data)) return false;
  if (data.constructor === Object) {
    let keys = Object.keys(data);
    let fkeys = keys.filter((e) => !e.startsWith('__'));
    if (data.uri && is_simple_type(data.uri) && fkeys.length <= 2) return true;
    if (data.value && fkeys.length === 1 && is_simple_value(data.value)) {
      return true;
    }
    return false;
  }
  return true;
}

function isEqualLength(a, b) {
  let a1 = a.filter((e) => !e.startsWith('__'));
  let b1 = b.filter((e) => !e.startsWith('__'));
  return a1.length === b1.length;
}

function is_single_kind_array(data) {
  if (data === undefined || data === null) return false;
  if (data.length <= 1) return false;

  if (data[0].constructor !== Object) return false;
  let skeys = Object.keys(data[0]);
  let l = skeys.length;

  for (var i = 0; i < data.length; i++) {
    if (data[i].constructor !== Object) return false;
    let keys = Object.keys(data[i]);
    let ll = keys.length;
    if (isEqualLength(keys, skeys)) return false;
    for (var j = 0; j < ll; j++) {
      let k = keys[j];
      if (k.startsWith('__')) continue;
      if (data[0][k] === undefined) {
        return false;
      }
      if (!is_simple_data(data[i][j])) return false;
    }
  }
  return true;
}

function is_simple_type(data) {
  if (data.constructor === Object) return false;
  if (Array.isArray(data)) return false;
  return true;
}

function is_simple_value(data) {
  if (is_simple_type(data)) return true;
  if (data.constructor !== Object) return false;
  let keys = Object.keys(data);
  let fkeys = keys.filter((e) => !e.startsWith('__'));
  if (data.uri && is_simple_type(data.uri) && fkeys.length <= 2) return true;
  return false;
}
function get_data_type(data) {
  if (data === undefined || data === null) return DATA_TYPE_SINGLE_VALUE;

  if (Array.isArray(data)) {
    if (is_single_kind_array(data)) return DATA_TYPE_ARRAY_SINGLE_KIND_VALUE;
    for (let i = 0; i < data.length; i++) {
      if (!is_simple_data(data[i])) return DATA_TYPE_ARRAY_COMPLEX_VALUE;
    }
    return DATA_TYPE_ARRAY_SINGLE_VALUE;
  }

  if (data.constructor === Object) {
    let keys = Object.keys(data);
    let fkeys = keys.filter((e) => !e.startsWith('__'));
    if (data.uri && is_simple_type(data.uri) && fkeys.length <= 2)
      return DATA_TYPE_LINK_VALUE;
    if (data.value && fkeys.length === 1 && is_simple_value(data.value)) {
      return DATA_TYPE_SINGLE_VALUE;
    }
    for (let i = 0; i < keys.length; i++) {
      if (!is_simple_data(data[keys[i]])) return DATA_TYPE_DICT_COMPLEX_VALUE;
    }
    return DATA_TYPE_DICT_SINGLE_VALUE;
  }

  return DATA_TYPE_SINGLE_VALUE;
}

export function refine_html_text(html_str) {
  var result = html_str.replace(/[&]lt[;].*[&]gt[;]/g, ', ');
  result = result.replace(/[<].*[>]/g, ', ');
  result = result.replace(/  /g, ' ');
  return result;
}

function RemarkBtn(props) {
  return (
    <div>
      <IconButton size="small">
        <ThumbUpOutlinedIcon />
        {props['##yes##'] || 0}
      </IconButton>
      <IconButton size="small">
        <ThumbDownOutlinedIcon />
        {props['##no##'] || 0}
      </IconButton>
      <IconButton size="small">
        <EditIcon />
        Edit
      </IconButton>
    </div>
  );
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function lookupTableHeader(data, ord, result = {}) {
  if (Array.isArray(data)) {
    if (data.length <= 1) return [];

    data.map((e) => lookupTableHeader(e, ord, result));
  }
  if (data.constructor == Object) {
    var key_list = Object.keys(data);
    if (key_list.length === 2 && data.uri && data.label) {
      return Object.keys(result);
    }
    var sorted_key_list = key_list;
    if (ord) {
      sorted_key_list = key_list.sort((a, b) => {
        var aa = parseInt(ord[a]);
        var bb = parseInt(ord[b]);
        return aa - bb;
      });
    }
    sorted_key_list.forEach((e) => {
      result[e] = 1;
    });
  }
  return Object.keys(result);
}

var g_index = 0;

function CreateLinkValue(data: any, remark: any = false, action: any = {}, ignoreSet: any) {
  if (data.label === '(Payment is required to access the data)') {
    return (
      <Link to="#" underline="none" component={RouterLink}>
        <LockIcon />
      </Link>
    );
  }
  let label = data.label ? data.label : data.uri;

  if (isDeletedData(data, ignoreSet)) return null;

  return (
    <Stack direction="row">
      <Link
        component={RouterLink}
        to="#"
        underline="none"
        // metauri={data.uri}
        // metalabel={label}
        onClick={action.hyperlink}
      >
        {label}
      </Link>
      {remark && createVoteValue(data, action)}
    </Stack>
  );
}

function getHyperValue (
  data: any,
  index: any = 0,
  remark: any = false,
  action?: any,
  ignoreSet?: any,
) {
  g_index += 1;
  if (Array.isArray(data)) {
    return data.map((e, index) => getHyperValue(e, index, remark));
  }

  if (data.constructor === Object) {
    var key_list = Object.keys(data);
    var fkey_list = key_list.filter((e) => !e.startsWith('__'));
    if (fkey_list.length <= 2 && data.uri) {
      if (data.label === '(Payment is required to access the data)') {
        return (
          <div>
            <Link key={index} to="#" underline="none" component={RouterLink}>
              <LockIcon />
            </Link>
          </div>
        );
      }
      let label = data.label ? data.label : data.uri;
      if (isDeletedData(data, ignoreSet)) return null;
      return (
        <Stack direction="row" spacing={2}>
          <Link
            key={index}
            component={RouterLink}
            to="#"
            underline="none"
            // metauri={data.uri}
            // metalabel={label}
            onClick={action.hyperlink}
          >
            {label}
          </Link>
          {remark && createVoteValue(data)}
        </Stack>
      );
    }

    var tableHeader = lookupTableHeader(data, null);
    if (tableHeader.length > 0) {
      return (
        <TableContainer key={index} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeader.map((e, index) => {
                  return (
                    <StyledTableCell align="center" key={index}>
                      {e}
                    </StyledTableCell>
                  );
                })}
                {remark && (
                  <StyledTableCell align="center" key="remark">
                    remark
                  </StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {CreateTableRow(
                '',
                data,
                tableHeader,
                g_index,
                remark,
                action.hyperlink
              )}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
    return (
      <div key={index}>
        <Typography sx={{ wordBreak: 'break-all', alignSelf: 'center' }}>{JSON.stringify(data)}</Typography>
        {remark && <RemarkBtn />}
      </div>
    );
  }

  if (data === '(Payment is required to access the data)') {
    return (
      <div key={index}>
        <LockIcon key={index} />
      </div>
    );
  }

  return (
    <div key={index}>
      <Typography sx={{ wordBreak: 'break-all', alignSelf: 'center' }}>{JSON.stringify(data)}</Typography>
    </div>
  );
};

function isDeletedData(d, s) {
  for (var i = 0; i < s.length; i++) {
    if (d['__id'] === s[i]['__id']) return true;
  }
  return false;
}

function CreateTableRow(
  prop: any,
  data: any,
  fields: any,
  pindex: any,
  remark: any,
  action: any,
  ignoreSet: any = []
) {
  if (isDeletedData(data, ignoreSet)) return null;
  //console.log(`${prop}_${index}`)
  return (
    <StyledTableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      onClick={(e) => {
        if (action.hyperlink) action.hyperlink(e);
      }}
    >
      {fields.map((k, index) => {
        var cont = data[k];
        if (cont === undefined) {
          return <StyledTableCell align="center" key={index}></StyledTableCell>;
        }
        return (
          <StyledTableCell
            align="center"
            key={index}
            onClick={(e) => {
              if (action.hyperlink) action.hyperlink(e);
            }}
          >
            {
              CreateView(cont, null, false, action, ignoreSet)
              //getHyperValue(cont, 0, false, action)
            }
          </StyledTableCell>
        );
      })}
      {remark && createVoteValue(data, action)}
    </StyledTableRow>
  );
}

function CreateValue(prop, result, ord, remark, clickHyperLink = null) {
  //console.log(JSON.stringify(result))
  var tableHeader = lookupTableHeader(result, ord);
  if (Array.isArray(result)) {
    if (tableHeader.length > 0) {
      return (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeader.map((e, index) => {
                  return (
                    <StyledTableCell align="center" key={index}>
                      {e}
                    </StyledTableCell>
                  );
                })}
                {remark && (
                  <StyledTableCell align="center" key="remark">
                    remark
                  </StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {result.map((e, index) => {
                return CreateTableRow(
                  prop,
                  e,
                  tableHeader,
                  index,
                  remark,
                  clickHyperLink
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
    return (
      <List>
        {result.map((e, index) => {
          return (
            <ListItem key={index}>
              {getHyperValue(e, index, remark, clickHyperLink)}
            </ListItem>
          );
        })}
      </List>
    );
  }
  return getHyperValue(result, 0, remark, clickHyperLink);
}

export function getNumber(query) {
  let q = query.replace(/ /g, '');
  var a = parseInt(q, 10);
  if (a == q) {
    return q;
  }
  return '';
}

export const setGlobalVal = (key: string, val: any, dispatch: any) => {
  const { abn, acn, entityName } = useSelector((store) => store.global);
  switch (key) {
    case 'ABN':
      // console.log('ABN value=', val)
      if (abn !== val.trim()) {
        dispatch(updateGlobalState({
          abn: val.trim(),
        }));
      }
      break;
    case 'ACN':
    case 'ASIC Number':
      // console.log('ASIC NUMBER=', val)
      if (acn !== val.trim() && val.trim() !== '') {
        dispatch(updateGlobalState({
          acn: val.trim(),
        }));
      }
      break;
    case 'Entity Name':
    case 'Legal Name':
    case 'Company Name':
    case 'Current Name':
    case 'Trading Name':
    case 'Business Name':
    case 'Business':
      {
        let name_array = [];
        if (Array.isArray(val)) {
          name_array = val;
        } else if (val.value) {
          name_array = val.value;
        } else {
          val = val.trim();
          name_array = val.split(';');
        }
        //console.log(name_array)
        let filtered_array = name_array.filter((e) => {
          return entityName.indexOf(e) === -1;
        });
        //console.log(filtered_array)
        if (filtered_array.length > 0) {
          var newVal = [...entityName, ...filtered_array];
          dispatch(updateGlobalState({
            entityName: newVal,
          }));
        }
      }
      break;
    default:
      break;
  }
}

export function CreateProperty(
  group,
  result,
  ord,
  remark = false,
  action = {}
) {
  return CreateView(result, ord, false, action);
  /*
  if (Array.isArray(result)) {
      return CreateValue(group, result, ord, remark, clickHyperLink);
  }
  
  if (result.constructor === Object) {
      var key_list = Object.keys(result);

      var sorted_key_list = key_list;
      if (ord) {
          sorted_key_list = key_list.sort((a,b) => {
              var aa = parseInt(ord[a]);
              var bb = parseInt(ord[b]);
              return aa-bb;
          });
      }

      return (
          <div>
              {
                  sorted_key_list.map((e,index)=>{
                      //console.log(`${group}_${index}`)
                      return (
                          
                          <Grid key={index}>
                              <Grid item sx={{textAlign:"left", ml:5, mr:5}}>
                                  <Typography variant="BUTTON TEXT">{e}</Typography>
                              </Grid>
                              <Grid item sx={{textAlign:"left", ml:10, mr:5, mt:1, mb:2}}>
                              {
                                  CreateValue(`${group}_${e}`, result[e], ord, remark, clickHyperLink)
                              }
                              </Grid>
                          </Grid>
                      )
                  })
              }
              
          </div>
      )
  }

  return (
      <Typography>
          {result.toString()}
      </Typography>
  )
  */
}

function createVoteValue(result: any, action?: any) {
  let y = result.__yes || 0;
  let n = result.__no || 0;
  let id = result.__id || '';
  if (id == '') return null;
  return (
    <Stack
      direction="row"
      ml={1}
      justifyContent="flex-end"
    >
      <Button
        variant="text"
        color="success"
        startIcon={<ThumbUpOutlinedIcon />}
        onClick={() => {
          if (action.agree) action.agree(id, result);
        }}
      >
        {y}
      </Button>
      <Button
        variant="text"
        color="error"
        startIcon={<ThumbDownOutlinedIcon />}
        onClick={() => {
          if (action.disagree) action.disagree(id, result);
        }}
      >
        {n}
      </Button>
      <Button
        variant="text"
        color="error"
        startIcon={<DeleteOutlineOutlined />}
        onClick={() => {
          if (action.delete) action.delete(id, result);
        }}
      />
    </Stack>
  );
}

/*
  action:
    agree(id)
    disagree(id)
    delete(id)
    hyperlink()
*/
function CreateView(result, ord, remark = false, action = {}, ignoreSet = []) {
  let type = get_data_type(result);
  let PAY_INFORM = '(Payment is required to access the data)';

  if (type === DATA_TYPE_SINGLE_VALUE) {
    if (result === PAY_INFORM || result?.value === PAY_INFORM) {
      return (
        <LockIcon />
      );
    } else {
      if (result['__id']) {
        if (isDeletedData(result, ignoreSet)) return null;
        if (result.value.label === PAY_INFORM) {
          remark = false;
        }
        return (
          <Stack direction="column" spacing={1} ml={1}>
            {CreateView(result.value, ord, false, action, ignoreSet)}
            {remark && createVoteValue(result, action)}
          </Stack>
        );
      } else if (result.value) {
        return (
          <Typography sx={{ wordBreak: 'break-all', alignSelf: 'center' }}>{result.value.toString()}</Typography>
        );
      } else {
        return (
          <Typography sx={{ wordBreak: 'break-all', alignSelf: 'center' }}>{result.toString()}</Typography>
        );
      }
    }
  } else if (type === DATA_TYPE_LINK_VALUE) {
    return CreateLinkValue(result, remark, action, ignoreSet);
  } else if (
    type === DATA_TYPE_ARRAY_COMPLEX_VALUE ||
    type === DATA_TYPE_ARRAY_SINGLE_VALUE
  ) {
    return (
      <List
        sx={{
          p: 0
        }}
      >
        {result.map((e, index) => {
          return (
            <ListItem key={index} sx={{ mb: 1, px: 1 }}>
              {CreateView(e, ord, remark, action, ignoreSet)}
            </ListItem>
          );
        })}
      </List>
    );
  } else if (type === DATA_TYPE_ARRAY_SINGLE_KIND_VALUE) {
    return CreateTableData(result, ord, remark, action, ignoreSet);
  } else if (type === DATA_TYPE_DICT_SINGLE_VALUE) {
    return CreateSimpleDictData(result, ord, remark, action, ignoreSet);
  } else if (type === DATA_TYPE_DICT_COMPLEX_VALUE) {
    return CreateComplexDictData(result, ord, remark, action, ignoreSet);
  }
}

function CreateComplexDictData(result, ord, remark, action, ignoreSet) {
  var key_list = Object.keys(result);

  var sorted_key_list = key_list.filter((e) => !e.startsWith('__'));
  if (ord) {
    sorted_key_list = sorted_key_list.sort((a, b) => {
      var aa = parseInt(ord[a]);
      var bb = parseInt(ord[b]);
      return aa - bb;
    });
  }

  return (
    <Box>
      {sorted_key_list.map((e, index) => {
        if (e === '##order##' || e === 'predicted_fee') return null;
        return (
          <Accordion
            defaultExpanded={true}
            key={index}
            sx={{
              backgroundColor: 'background.default',
              borderRadius: '0px'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6" sx={{ wordBreak: 'break-all', alignSelf: 'center' }}>{e}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                p: 1
              }}
            >
              {CreateView(result[e], ord, remark, action, ignoreSet)}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}

function CreateSimpleDictData(result, ord, remark, action, ignoreSet) {
  var key_list = Object.keys(result);

  var sorted_key_list = key_list.filter((e) => !e.startsWith('__'));
  if (ord) {
    sorted_key_list = sorted_key_list.sort((a, b) => {
      var aa = parseInt(ord[a]);
      var bb = parseInt(ord[b]);
      return aa - bb;
    });
  }

  return (
    <div>
      {sorted_key_list.map((e, index) => {
        if (e === '##order##') return null;
        //console.log(`${group}_${index}`)
        return (
          <Grid
            container
            key={index}
            sx={{ textAlign: 'left', mb: 1 }}
          >
            <Grid item xs={4}>
              <Typography sx={{ wordBreak: 'break-all', alignSelf: 'center' }} variant="h6">{e}</Typography>
            </Grid>
            <Grid item xs={8} sx={{ textAlign: 'left', }}>
              {CreateView(result[e], ord, remark, action, ignoreSet)}
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
}

function CreateTableData(result, ord, remark, action, ignoreSet) {
  var tableHeader = lookupTableHeader(result, ord);
  if (tableHeader.length === 0) {
    return (
      <List>
        {result.map((e, index) => (
          <ListItem key={index}>
            {CreateView(e, ord, remark, action, ignoreSet)}
          </ListItem>
        ))}
      </List>
    );
  }
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeader.map((e, index) => {
              return (
                <StyledTableCell align="center" key={index}>
                  {e}
                </StyledTableCell>
              );
            })}
            {remark && (
              <StyledTableCell align="center" key="remark">
                remark
              </StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {result.map((e, index) => CreateTableRow(
            '',
            e,
            tableHeader,
            index,
            remark,
            action,
            ignoreSet
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function CreateHeader(
  result,
  ord,
  discount = 1,
  remark = false,
  action = {},
  ignoreSet = []
) {
  return CreateView(result, ord, true, action, ignoreSet);
}

export async function request(resolve, address, fee, reason) {
  axios
    .post(makeMessage(GlobalParams.BSEARCH_URI, 'request'), {
      address,
      fee,
      reason,
    })
    .then((response) => {
      resolve(response.data);
    });
}

export const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));
