const tokenHost = 'bsearchau.accziom.com';
// const tokenHost = 'localhost';
const Params = {
  token_host: tokenHost,
  // token_server_url: `https://${token_host}:8886`,
  token_server_url: `http://${tokenHost}:8885`,
  token_wss_server_url: `ws://${tokenHost}:8889`,
  token_admin_server_url: `https://${tokenHost}:8884`,
};

export default Params;
