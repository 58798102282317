import { useState, type FC, useEffect } from 'react';
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Drawer,
  IconButton,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { NAVBAR_HEIGHT } from 'src/globals';
import { useWeb3React } from '@web3-react/core';
import { Close, Menu, Person } from '@material-ui/icons';
import MainMenu from './MainMenu';
import { useDispatch, useSelector } from 'src/store';
import { setAddress, setLogged, setSigning, setTryingLogin } from 'src/slices/user';
import { updateGlobalState } from 'src/slices/global';
import ConnectPad from '../pad/ConnectPad';
import { useLocation } from 'react-router';
import { GlobalParams } from 'src/config';
import { personalSign } from 'src/utils/metamask';
import toast from 'react-hot-toast';
import axios from 'axios';
import { makeMessage } from '../pad/bsearch/commontool';
import Params from '../pad/merc/config';
import { Web3Provider } from '@ethersproject/providers';

const { Contract } = require('ethers');

export const createWebSocket = (url) => {
  if (window.WebSocket) return new WebSocket(url);
  toast.error('Web Socket is disabled in your current browser.');
  return null;
};

const AccountInfo: FC = () => {
  const dispatch = useDispatch();
  const context = useWeb3React();
  const { connected, address, logged, isTryingLogin, isSigning } = useSelector((store) => store.user);
  const { mrc20Contract } = useSelector((state) => state.global);
  const [webSocket, setWebSocket] = useState(null);
  const [openSignin, setOpenSignin] = useState(false);

  async function setEtherBalance(addr) {
    console.log(`etherbal(${addr})`);
    (window as any).ethereum
      .request({
        method: 'eth_getBalance',
        params: [addr, 'latest'],
      })
      .then((balance) => {
        const bal = parseFloat(balance) / 1e18;
        dispatch(updateGlobalState({
          etherBalance: bal,
        }));
      });
    /* const provider = new Web3Provider(window.ethereum);
      console.log(provider);
      if (provider) {
          let bal = await provider.getBalance(mrcAddress);
          bal = BigNumber.from(bal).toNumber()
          dispatch({
              etherBalance: bal
          });
          return bal;
      } */
  }

  async function setContract(callback_fn) {
    if (mrc20Contract != null) {
      return;
    }
    try {
      axios.get(makeMessage(Params.token_server_url, '/get_merc_token')).then(async (response) => {
        if (response && response.data) {
          const res = response.data;
          if (res.status) {
            const { merc_address: mercAddress, merc_abi: mercAbi, mercOwner, exchanger } = res.data;
            const contract = await new Contract(
              mercAddress,
              mercAbi,
              new Web3Provider((window as any).ethereum)
            );
            callback_fn(contract, mercAddress, mercOwner, exchanger);
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  // const connectToTokenServer = () => {
  //   dispatch(setLogged(false));
  //   const ws = createWebSocket(GlobalParams.MERC_TOKEN_WS_URL);
  //   if (!ws) return;
  //   ws.onopen = () => {
  //     console.log(`mrcaddress=${context.account}`);
  //     // ws.send(`${context.account}`)
  //     setWebSocket(ws);
  //   };
  //   ws.onmessage = async (e) => {
  //     const data = JSON.parse(e.data);
  //     const addr = context.account;
  //     if (data.seed) {
  //       dispatch(setSigning(true));
  //       const signCode = await personalSign(data.seed, addr);
  //       console.log(signCode);
  //       const output = {
  //         type: 'register',
  //         seed: data.seed,
  //         address: addr,
  //         sign_code: signCode,
  //       };
  //       ws.send(JSON.stringify(output));
  //       return;
  //     }
  //     console.log(data);
  //     dispatch(setSigning(false));
  //     dispatch(setLogged(true));
  //     const { nft, transaction, balance, statistics } = data;
  //     dispatch(updateGlobalState({
  //       mercBalance: balance,
  //       mercTransaction: transaction,
  //       nft,
  //       mercStatistics: statistics,
  //       logged: true,
  //     }));
  //   };
  //   ws.onclose = () => {
  //     console.log('closed ws server.');
  //     dispatch(setLogged(false));
  //     dispatch(updateGlobalState({
  //       logged: false
  //     }));
  //   };
  // };
  const connectToTokenServer = () => {
    dispatch(setLogged(false));
    const ws = createWebSocket(GlobalParams.MERC_TOKEN_WS_URL);
    if (!ws) return;
    ws.onopen = () => {
      console.log(`mrcaddress=${context.account}`);
      // ws.send(`${context.account}`)
      setWebSocket(ws);
      // dispatch(updateGlobalState({
      //   token_ws_server: ws,
      // }));
    };
    ws.onmessage = async (e) => {
      const data = JSON.parse(e.data);
      const addr = context.account;
      if (data.seed) {
        dispatch(setSigning(true));
        const signCode = await personalSign(data.seed, addr);
        console.log(signCode);
        const output = {
          type: 'register',
          seed: data.seed,
          address: addr,
          sign_code: signCode,
        };
        ws.send(JSON.stringify(output));
        return;
      }
      console.log(data);
      dispatch(setSigning(false));
      dispatch(setLogged(true));
      const { nft, transaction, balance, statistics } = data;
      dispatch(updateGlobalState({
        mercBalance: balance,
        mercTransaction: transaction,
        nft,
        mercStatistics: statistics,
        logged: true,
      }));
      const { address: Address } = data;
      const bal = data.balance.layer2;
      const totalBtc = data.btc_reserves.total_balance;
      const newVal = {
        mrc2Balance: bal,
        mrcBalance: data.balance.layer1,
        btcReserveBalance: totalBtc,
        mercReserves: data.mrc_reserves,
        exchangeBalance: data.exchanger_balance,
        totalSupply: data.mrc_total_supply,
        layer2Total: data.layer2_total,
        transactionHistory: data.transaction,
        btcReserveList: data.btc_reserve_info,
        mercBalance: data.balance,
        mercTransaction: data.transaction,
        nft: data.nft,
        mercStatistics: data.statistics,
        logged: true,
      };
      dispatch(updateGlobalState(newVal));
      setEtherBalance(Address);
    };
    ws.onclose = () => {
      console.log('closed ws server.');
      dispatch(setLogged(false));
      dispatch(updateGlobalState({
        logged: false,
      }));
    };
    setContract(
      async (contract, mercAddress, mercOwner, exchanger) => {
        const ts = (await contract.totalSupply()).toNumber();
        dispatch(updateGlobalState({
          mrc20Contract: contract,
          totalSupply: ts,
          mercContractAddress: mercAddress,
          mercOwner,
          exchanger,
        }));
      }
    );
  };

  const handleSignIn = async () => {
    connectToTokenServer();
  };

  useEffect(() => {
    if (!isTryingLogin) return;
    dispatch(setTryingLogin(false));
    connectToTokenServer();
  }, [isTryingLogin]);

  useEffect(() => {
    if (address !== context.account) {
      dispatch(setAddress(context.account)); // eslint-disable-line @typescript-eslint/dot-notation
      if (logged) {
        webSocket.close();
        setWebSocket(null);
      }
      dispatch(setLogged(false));
      dispatch(updateGlobalState({
        mercBalance: {},
        mercTransaction: [],
        nft: null,
        mercStatistics: {},
        logged: false,
      }));
    }
  }, [context.account]);

  if (connected && logged) {
    return (
      <>
        <Box color="inherit">
          <Badge
            variant="dot"
            color="error"
            sx={{ mr: 2 }}
          >
            <Person
              sx={{
                color: 'primary.main',
              }}
            />
          </Badge>
        </Box>
      </>
    );
  }
  return (
    <Box>
      <Button
        variant="contained"
        onClick={() => {
          setOpenSignin(true);
        }}
        size="small"
        sx={{
          mr: 1,
        }}
      >
        Sign in
      </Button>
      <Dialog
        open={openSignin}
        onClose={() => setOpenSignin(false)}
      >
        <Box
          sx={{
            backgroundColor: 'background.default',
            height: '400px',
          }}
        >
          {connected ? (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  height: '60px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  px: 1
                }}
              >
                <Box
                  flexGrow={1}
                  pl={1}
                >
                  <Typography>
                    Sign in
                  </Typography>
                </Box>
                <IconButton
                  type="button"
                  sx={{ p: '10px 6px 10px 10px' }}
                  aria-label="search"
                  onClick={() => setOpenSignin(false)}
                >
                  <Close />
                </IconButton>
              </Box>
              <Typography
                variant="subtitle1"
                sx={{
                  backgroundColor: 'aliceblue',
                  borderRadius: '6px',
                  p: 1,
                  m: 1,
                  mt: 3,
                }}
              >
                Would you like to sign in to work with your Merc wallet?
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  py: 6,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 'stretch'
                }}
              >
                <img
                  src="/favicon.svg"
                  width="48px"
                  alt="bSearch"
                />
                <Box sx={{ width: '40%', px: 2 }}>
                  <LinearProgress />
                </Box>
                <img
                  src="static/MERc.png"
                  width="48px"
                  alt="bSearch"
                />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleSignIn}
                  sx={{
                    width: '60%',
                    my: 3
                  }}
                  startIcon={
                    isSigning ? (
                      <CircularProgress
                        sx={{
                          color: 'background.default'
                        }}
                        size={16}
                      />
                    ) : (
                      <></>
                    )
                  }
                >
                  {isSigning ? 'Signing...' : 'Sign in'}
                </Button>
              </Box>
            </Box>
          ) : (
            <ConnectPad onClose={() => setOpenSignin(false)} />
          )}
        </Box>
      </Dialog>
    </Box>
  );
};

const DashboardNavbar: FC = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const { searchMode } = useSelector((store) => store.user);
  const { pathname } = useLocation();

  return (
    <Box
      width="100%"
      sx={{
        height: NAVBAR_HEIGHT,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <IconButton
          type="button"
          sx={{ p: '10px 6px 10px 10px' }}
          aria-label="search"
          onClick={() => setOpenMenu(true)}
        >
          <Menu />
        </IconButton>
      </Box>
      <Drawer
        open={openMenu}
        onClose={() => setOpenMenu(false)}
      >
        <MainMenu handleClose={() => setOpenMenu(false)} />
      </Drawer>
      <Box
        flexGrow={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          color: 'gray',
          fontSize: '24px',
          pt: 0.5,
        }}
      >
        {pathname === '/bsearch' && searchMode && (
          <img
            src="static/bsearch.png"
            width={160}
            alt="Business Search"
          />
        )}
        {pathname === '/tax-genii' && (
          <img
            src="static/Tax Genii.png"
            width={160}
            alt="Business Search"
          />
        )}
        {pathname === '/wallet' && (
          <Typography
            variant="h5"
          >
            Wallet
          </Typography>
        )}
      </Box>
      <AccountInfo />
    </Box>
  );
};

export default DashboardNavbar;
