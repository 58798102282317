import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';

interface GlobalState {
  pane: string;
  curView: string;
  entityName: any[];
  entityID: string;
  abn: string;
  acn: string;
  lei: string;
  rdf: any;
  predictedFee: number;
  account: string;
  anzicCode: any;
  anzicCodeByCode: any;
  anzicQuery: any[];
  mercBalance: any;
  mercTransaction: any;
  mercStatistics: any;
  nft: any[];
  logged: boolean;
  connected: boolean;
  subURI: string;
  subTitle: string;
  openDetails: boolean;
  rdfHistory: any;
  subRdfHistory: any;
  abnHistory: any;
  acnHistory: any;
  tpbHistory: any;
  truelocalHistory: any;
  asxHistory: any;
  leiHistory: any;
  gbgHistory: any;
  bingHistory: any;
  mapHistory: any;
  anzicHistory: any;
  commenter: string;
  chatHistoryReload: boolean;
  btcReserveList: any[];
  seed: string;
  mrcAddress: string;
  btcAddress: string;
  btcBalance: number;
  mrcBalance: number;
  mrc2Balance: string;
  amount: 0;
  mrc20Contract: any;
  token_ws_server: any;
  sendMsg: any;
  layer2: boolean;
  etherBalance: any;
  totalSupply: any;
  btcReserves: any;
  btcOperationFee: any;
  btcReserveBalance: any;
  layer2Total: any;
  mercReserves: any;
  transactionHistory: any[];
  mercContractAddress: string;
  mercOwner: string;
  exchanger: string;
  exchangeBalance: string;
  inputAmountError: boolean;
  btc: number;
}

const initialState: GlobalState = {
  pane: 'Search',
  curView: 'ACCZIOM',
  entityName: [],
  entityID: '',
  abn: '',
  acn: '',
  lei: '',
  rdf: {},
  predictedFee: 0,
  account: '',
  anzicCode: null,
  anzicCodeByCode: null,
  anzicQuery: [],
  mercBalance: {},
  mercTransaction: null,
  mercStatistics: {},
  nft: [],
  logged: false,
  connected: false,
  subURI: '',
  subTitle: '',
  openDetails: false,
  rdfHistory: { query: '', fee: 0, result: {} },
  subRdfHistory: { query: '', fee: 0, result: {} },
  abnHistory: { query: '', result: {} },
  acnHistory: { query: '', result: {} },
  tpbHistory: { query: '', result: {} },
  truelocalHistory: { query: '', result: {} },
  asxHistory: { query: '', result: {} },
  leiHistory: { query: '', result: {} },
  gbgHistory: { query: '', result: {} },
  bingHistory: { query: '', result: {} },
  mapHistory: { query: '', result: {} },
  anzicHistory: { query: '', result: {} },
  commenter: '',
  chatHistoryReload: false,
  // copid from merc_front project
  btcReserveList: [],
  seed: '',
  mrcAddress: '',
  btcAddress: '',
  btcBalance: 0,
  mrcBalance: 0, // ''
  mrc2Balance: '',
  amount: 0,
  mrc20Contract: null,
  token_ws_server: null,
  sendMsg: '',
  layer2: true,
  etherBalance: '',
  totalSupply: '',
  btcReserves: '',
  btcOperationFee: '',
  btcReserveBalance: '',
  layer2Total: 0,
  mercReserves: 0,
  transactionHistory: [],
  mercContractAddress: '',
  mercOwner: '',
  exchanger: '',
  exchangeBalance: '',
  inputAmountError: false,
  btc: 0,
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    clearState(state: GlobalState) {
      state.pane = 'Search';
      state.curView = 'ACCZIOM';
      state.entityName = [];
      state.entityID = '';
      state.abn = '';
      state.acn = '';
      state.lei = '';
      state.rdf = {};
      state.predictedFee = 0;
      state.account = '';
      state.anzicCode = null;
      state.anzicCodeByCode = null;
      state.anzicQuery = [];
      state.mercBalance = {};
      state.mercTransaction = null;
      state.mercStatistics = {};
      state.nft = [];
      state.logged = false;
      state.connected = false;
      state.subURI = '';
      state.subTitle = '';
      state.openDetails = false;
      state.rdfHistory = { query: '', result: {} };
      state.subRdfHistory = { query: '', result: {} };
      state.abnHistory = { query: '', result: {} };
      state.acnHistory = { query: '', result: {} };
      state.tpbHistory = { query: '', result: {} };
      state.truelocalHistory = { query: '', result: {} };
      state.asxHistory = { query: '', result: {} };
      state.leiHistory = { query: '', result: {} };
      state.gbgHistory = { query: '', result: {} };
      state.bingHistory = { query: '', result: {} };
      state.mapHistory = { query: '', result: {} };
      state.anzicHistory = { query: '', result: {} };
      state.commenter = '';
      state.chatHistoryReload = false;
    },
    updateGlobalState(state: GlobalState, action: PayloadAction<any>): void {
      const newState = action.payload;
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    setGlobalVal(state: GlobalState, action: PayloadAction<{ key: any, val: any }>): void {
      const { key, val } = action.payload;
      const { abn, acn, entityName } = state;
      switch (key) {
        case 'ABN':
          // console.log('ABN value=', val)
          if (abn !== val.trim()) {
            state.abn = val.trim();
          }
          break;
        case 'ACN':
        case 'ASIC Number':
          // console.log('ASIC NUMBER=', val)
          if (acn !== val.trim() && val.trim() !== '') {
            state.acn = val.trim();
          }
          break;
        case 'Entity Name':
        case 'Legal Name':
        case 'Company Name':
        case 'Current Name':
        case 'Trading Name':
        case 'Business Name':
        case 'Business':
          {
            let nameArray = [];
            if (Array.isArray(val)) {
              nameArray = val;
            } else if (val.value) {
              nameArray = val.value;
            } else {
              const val1 = val.trim();
              nameArray = val1.split(';');
            }
            const filteredArray = nameArray.filter((e) => entityName.indexOf(e) === -1);
            if (filteredArray.length > 0) {
              const newVal = [...entityName, ...filteredArray];
              state.entityName = newVal;
            }
          }
          break;
        default:
          break;
      }
    }
  }
});

export const clearGlobalState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const updateGlobalState = (state: any) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateGlobalState(state));
};

export const setGlobalVal = (key: string, val: any) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setGlobalVal({ key, val }));
};

export const { reducer } = slice;

export default slice;
