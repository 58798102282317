import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import DashboardLayout from 'src/components/dashboard/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
// import GuestGuard from './components/layout/GuestGuard';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const Home = Loadable(lazy(() => import('src/pages/home')));
const BSearch = Loadable(lazy(() => import('src/pages/bsearch')));
const ChatbotView = Loadable(lazy(() => import('src/components/pad/ChatbotView')));
const LegalSearch = Loadable(lazy(() => import('src/components/pad/LegalSearch')));
const RelationRecognitionView = Loadable(lazy(() => import('src/components/pad/RelationRecognition')));
const ChatbotHistoryView = Loadable(lazy(() => import('src/components/pad/ChatHistory')));
const ChatbotApiView = Loadable(lazy(() => import('src/components/pad/TaxGeniiApi')));

const ProfileView = Loadable(lazy(() => import('src/components/pad/Profile')));
const WalletView = Loadable(lazy(() => import('src/components/pad/merc')));
const NftView = Loadable(lazy(() => import('src/components/pad/NFT')));

const NotFound = Loadable(lazy(() => import('src/pages/NotFound')));

const routes: RouteObject[] = [
  // root
  {
    path: '/',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: '/',
        element: <Home />
      }
    ]
  },
  // home
  {
    path: '/home',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: '/home',
        element: <Home />
      }
    ]
  },
  {
    path: '/help',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: '/help',
        element: <Home />
      }
    ]
  },
  {
    path: '/',
    element: (
      <DashboardLayout />
    ),
    children: [{
      path: '/*',
      element: <NotFound />
    }]
  },
  {
    path: '/bsearch',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: '/bsearch',
        element: <BSearch />
      }
    ]
  },
  {
    path: '/tax-genii-history',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: '/tax-genii-history',
        element: <ChatbotHistoryView />
      }
    ]
  },
  {
    path: '/tax-genii-api',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: '/tax-genii-api',
        element: <ChatbotApiView />
      }
    ]
  },
  {
    path: '/tax-genii',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: '/tax-genii',
        element: <ChatbotView />
      }
    ]
  },
  {
    path: '/legal-database',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: '/legal-database',
        element: <LegalSearch />
      }
    ]
  },
  {
    path: '/relation-recognition',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: '/relation-recognition',
        element: <RelationRecognitionView />
      }
    ]
  },
  {
    path: '/nft',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: '/nft',
        element: <NftView />
      }
    ]
  },
  {
    path: '/profile',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: '/profile',
        element: <ProfileView />
      }
    ]
  },
  {
    path: '/mercoin',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: '/mercoin',
        element: <WalletView />
      }
    ]
  },
  {
    path: '/wallet',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: '/wallet',
        element: <WalletView />
      }
    ]
  },
  {
    path: '/',
    element: undefined,
    children: [{
      path: '/*',
      element: <NotFound />
    }]
  },
];

export default routes;
