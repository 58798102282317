/* eslint-disable */
import { FC, ReactNode, useEffect, useLayoutEffect, useState } from 'react';
import { Outlet, matchPath, useLocation } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import { Fab, Fade } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import DashboardTopbar from './DashboardTopbar';
import { setWindowScrollBottom } from 'src/slices/user';
import { useSelector, useDispatch } from 'src/store';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled('div')(
  ({ theme }) => (
    {
      backgroundColor: theme.palette.background.default,
      height: '100%',
    }
  )
);

interface GoToTopProps {
  trigger: boolean;
}
const GoToTop : FC<GoToTopProps> = (props) => {
  const { trigger } = props;
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 112, right: 16, zIndex: 2000 }}
      >
        <Fab
          size="small"
          aria-label="scroll back to top"
          sx={{
            opacity: '0.8'
          }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </Box>
    </Fade>
  );
};
GoToTop.propTypes = {
  trigger: PropTypes.bool,
};

const topbarPath = [
  '/home',
  '/',
  '/marketplace/organization',
  '/marketplace/microservice',
  '/marketplace/asset',
];
const bottombarPath = [
  '/home',
  '/',
  '/configuration',
  '/analysis'
];

function useWindowSize() {
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return height;
}

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [topbarVisible, setTopbarVisible] = useState(true);
  const [bottombarVisible, setBottombarVisible] = useState(true);
  const [scrollDir, setScrollDir] = useState('scrolling up');
  const [showGotoTop, setShowGotoTop] = useState(false);
  const { pathname } = useLocation();
  const { isWindowScrollBottom } = useSelector((state) => state.user);
  const height = useWindowSize();
  const [last, setLast] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    setScrollDir(window.pageYOffset > 0 && last <= height ? 'scrolling down' : 'scrolling up');
    setLast(height);
  }, [height]);
  useEffect(() => {
    setTopbarVisible(true || topbarPath.findIndex((path) => path === window.location.pathname) > -1);
    setBottombarVisible(bottombarPath.findIndex((path) => !!matchPath({ path, end: true }, window.location.pathname)) > -1);
    setScrollDir('scrolling up');
    setShowGotoTop(false);
  }, [pathname]);
  useEffect(() => {
    const threshold = 80;
    let lastScrollY = window.pageYOffset;
    let lastInnerHeight = window.innerHeight;
    let globalBottom = isWindowScrollBottom;
    let ticking = false;
    const updateScrollDir = () => {
      const maxHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight,
        document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
      const scrollY = maxHeight - window.innerHeight < window.pageYOffset ? maxHeight - window.innerHeight : window.pageYOffset;
      const isBottom = maxHeight - window.innerHeight <= window.pageYOffset;
      if (globalBottom !== isBottom) {
        globalBottom = isBottom;
        dispatch(setWindowScrollBottom(isBottom));
      }
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        lastInnerHeight = window.innerHeight;
        ticking = false;
        return;
      }
      let value = scrollY > lastScrollY ? 'scrolling down' : 'scrolling up';
      value = lastInnerHeight > window.innerHeight ? 'scrolling up' : value;
      setScrollDir(value);

      setShowGotoTop(scrollY > 300);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollDir]);
  return (
    <DashboardLayoutRoot
      sx={{
        // paddingTop: topbarVisible ? '42px' : '0px',
      }}
    >
      {topbarVisible && <DashboardTopbar />}
      <Outlet />
      <GoToTop
        trigger={showGotoTop}
      />
    </DashboardLayoutRoot>
  );
};
export default DashboardLayout;
