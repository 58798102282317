import { Box, Button, CircularProgress, IconButton, LinearProgress, Typography } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'src/store';
import { injected } from 'src/utils/metamask';
import { setConnected } from 'src/slices/user';
import { Close } from '@material-ui/icons';

const ConnectPad: FC<{ onClose: any; }> = ({ onClose }) => {
  const dispatch = useDispatch();
  const context = useWeb3React();
  const [activatingConnector, setActivatingConnector] = useState(null);
  const { chainId, activate, error } = context;
  const { connected } = useSelector((store) => store.user);
  console.log(injected, activatingConnector);
  const activating = injected === activatingConnector;
  const buttonText = (activating ? 'Connecting' : 'Connect to Wallet');

  useEffect(() => {
    dispatch(setConnected(!error && !!chainId));
  }, [chainId, error]);

  // useEffect(() => {
  //   if (activatingConnector && activatingConnector === connector) {
  //     setActivatingConnector(undefined);
  //   }
  // }, [activatingConnector, connector]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          height: '60px',
          alignItems: 'center',
          justifyContent: 'center',
          px: 1
        }}
      >
        <Box
          flexGrow={1}
          pl={1}
        >
          <Typography>
            Connect to Wallet
          </Typography>
        </Box>
        <IconButton
          type="button"
          sx={{ p: '10px 6px 10px 10px' }}
          aria-label="search"
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </Box>
      <Typography
        variant="subtitle1"
        sx={{
          backgroundColor: 'aliceblue',
          borderRadius: '6px',
          p: 1,
          m: 1,
          mt: 3
        }}
      >
        You MUST connect to Metamask to full access bsearch functions.
      </Typography>
      <Box
        sx={{
          width: '100%',
          py: 6,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'stretch'
        }}
      >
        <img
          src="/favicon.svg"
          width="48px"
          alt="bSearch"
        />
        <Box sx={{ width: '40%', px: 2 }}>
          <LinearProgress />
        </Box>
        <img
          src="static/metamask-icon.svg"
          width="48px"
          alt="bSearch"
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            if (!connected) {
              setActivatingConnector(injected);
              activate(injected);
            }
          }}
          sx={{
            width: '60%',
            my: 3
          }}
          startIcon={
            activating ? (
              <CircularProgress
                sx={{
                  color: 'background.default'
                }}
                size={16}
              />
            ) : (
              <></>
            )
          }
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};

export default ConnectPad;
