export const GlobalParams = {
  BSEARCH_URI: 'https://bsearchau.accziom.com:8007/',
  ANZIC_INFO_URI: 'https://bsearchau.accziom.com:8011/',
  ANZIC_WS_URL: 'wss://bsearchau.accziom.com:8018',
  MERC_TOKEN_WS_URL: 'wss://bsearchau.accziom.com:8887',
  PROFILE_SERVER_URL: 'https://bsearchau.accziom.com:8999/',
  GBG_API_COST: 52000,
  BING_API_COST: 1000,
  TAX_GENII_URI: 'https://bsearchau.accziom.com:5053/',
};

// old org cloudfront E2U2D826WTXP77
// old org bucket accziom-org
