/* eslint-disable */
import type { FC } from 'react';
import { Box, Button, Divider } from '@material-ui/core';
import { useDispatch, useSelector } from 'src/store';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { updateGlobalState } from 'src/slices/global';
import { setConnected, setLogged } from 'src/slices/user';
import { useNavigate } from 'react-router';
import { AccountBalanceWallet, BookOnline, DeviceUnknown, Person } from '@material-ui/icons';
import { Diversity2 } from '@mui/icons-material';

const serviceItems = [
  {
    value: 'Search',
    title: 'Integrated Search',
    href: '/bsearch',
    icon: '/static/bsearch_icon.png'
  },
  {
    value: 'Legal Database',
    title: 'Legal Database',
    href: '/legal-database',
    icon: DeviceUnknown
  },
  {
    value: 'Tax Chat',
    title: 'Tax Chat',
    href: '/tax-genii',
    icon: '/static/taxgenii_icon.png'
  },
  {
    value: 'Relation Recognition',
    title: 'Relation Recognition',
    href: '/relation-recognition',
    icon: Diversity2
  },
];

const others = [
  {
    value: 'Help',
    title: 'Help',
    href: '/help'
  },
];

// const profileItems = [
//   {
//     value: 'Profile',
//     title: 'Profile',
//     href: '/profile',
//     icon: Person
//   },
//   {
//     value: 'Wallet',
//     title: 'Wallet',
//     href: '/wallet',
//     icon: AccountBalanceWallet
//   },
//   {
//     value: 'NFT',
//     title: 'NFT',
//     href: '/nft',
//     icon: BookOnline
//   },
// ];

const ServiceListItems: FC<{ handleClose: any }> = ({ handleClose }) => {
  const navigate = useNavigate();
  return (
    <>
      <ListSubheader
        component="div"
        sx={{
          pt: 5,
          fontSize: '20px',
        }}
      >
        Services
      </ListSubheader>
      {serviceItems.map((item: any) => (
        <ListItemButton
          key={item.value}
          onClick={() => {
            navigate(item.href);
            handleClose();
          }}
          id={item.value}
        >
          <ListItemIcon>
            {typeof item.icon === 'string' ? (
              <img
                src={item.icon}
                alt={item.title}
                width="24px"
              />
            ) : (
              <item.icon />
            )}
          </ListItemIcon>
          <ListItemText primary={item.title} />
        </ListItemButton>
      ))}
    </>
  );
};

const OtherListItems: FC<{ handleClose: any }> = ({ handleClose }) => {
  const navigate = useNavigate();
  return (
    <>
      {others.map((item: any) => (
        <ListItemButton
          key={item.value}
          onClick={() => {
            navigate(item.href);
            handleClose();
          }}
          id={item.value}
        >
          <ListItemIcon>
            {item.icon ? (
              <img
                src={item.icon}
                alt="bsearch"
                width="24px"
              />
            ) : (
              <MenuBookIcon />
            )}
          </ListItemIcon>
          <ListItemText primary={item.title} />
        </ListItemButton>
      ))}
    </>
  );
};

const ProfileListItems: FC<{ handleClose: any }> = ({ handleClose }) => {
  const navigate = useNavigate();
  return (
    <>
      <ListSubheader
        component="div"
        sx={{
          fontSize: '20px',
        }}
      >
        Personal Info.
      </ListSubheader>
      {/* {profileItems.map((item) => (
        <ListItemButton
          key={item.value}
          onClick={() => {
            navigate(item.href);
            handleClose();
          }}
          id={item.value}
        >
          <ListItemIcon>
            {typeof item.icon === 'string' ? (
              <img
                src={item.icon}
                alt={item.title}
                width="24px"
              />
            ) : (
              <item.icon />
            )}
          </ListItemIcon>
          <ListItemText primary={item.title} />
        </ListItemButton>
      ))} */}
    </>
  );
};

const MainMenu: FC<{ handleClose: any }> = ({ handleClose }) => {
  const { logged } = useSelector((store) => store.global);
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'background.paper',
        minHeight: 'stretch',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          p: 1,
          pt: 3,
        }}
      >
        <img
          src="static/logo.svg"
          width={180}
          alt="Business Search"
        />
      </Box>
      <List component="nav">
        <ServiceListItems handleClose={handleClose} />
        <Divider sx={{ my: 1 }} />
        {logged && <ProfileListItems handleClose={handleClose} />}
        <OtherListItems handleClose={handleClose} />
      </List>
      <Box flexGrow={1} />
      <Divider />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          p: 2,
          my: 3
        }}
      >
        {!logged ? (
          <Button
            variant="text"
            onClick={() => {
              handleClose();
            }}
            size="small"
          >
            Sign in
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              dispatch(setLogged(false));
              dispatch(setConnected(false));
              dispatch(
                updateGlobalState({
                  mercBalance: {},
                  mercTransaction: [],
                  nft: null,
                  mercStatistics: {},
                  logged: false,
                })
              );
              handleClose();
            }}
            size="small"
          >
            Log out
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default MainMenu;
