import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import './i18n';
import RTL from 'src/components/RTL';
import useScrollReset from 'src/hooks/useScrollReset';
import useSettings from 'src/hooks/useSettings';
import routes from './routes';
import { createCustomTheme } from 'src/theme';
import { isMobile } from 'react-device-detect';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 5000;
  return library;
};

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  if (isMobile) {
    if (window.location.origin === 'https://bsearchau.accziom.com') {
      window.location.href = window.location.href.replace('bsearchau.accziom.com', 'm.bsearchau.accziom.com');
      return <div />;
    }
  }
  useScrollReset();
  const themeMobile = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: 'MOBILE'
  });

  return (
    <ThemeProvider theme={themeMobile}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <RTL direction={settings.direction}>
          <CssBaseline />
          <Toaster
            position="top-center"
            toastOptions={{
              duration: 4000
            }}
          />
          {content}
        </RTL>
      </Web3ReactProvider>
    </ThemeProvider>
  );
};

export default App;
